import axios from "../../../utility/api"

// const expiredLockerDetails = (item = false) => {
//   return {
//     id: item ? item.LockerId : "",
//     number: item ? item.LockerNumber : "",
//     location: item ? item.Location : "",
//     location_id: item ? item.LocationId : "",
//     place: item ? item.Place : "",
//     place_id: item ? item.PlaceId : "",
//     status: item ? item.Status : "",
//     status_duration: item ? item.StatusDuration : "",
//     status_expires_at: item ? item.StatusExpiresAt : "",
//     status_updated_at: item ? item.StatusUpdatedAt : "",
//     users: item ? item.Users : []
//   }
// }

const expiredLockers = (office, delivery) => {
  return {
      office: office ? office : [],
      delivery: delivery ? delivery : [],
    }
}

export const reloadExpiredLockers = value => {
  return dispatch => dispatch({ type: "RELOAD_EXPIRED_LOCKERS", payload: value })
}

export const getExpiredLockers = (token, reset) => (dispatch) =>
new Promise(function(resolve, reject){
  if(reset){
    dispatch({
      type: "GET_EXPIRED_LOCKERS_SUCCESS",
      payload: false
    })
  }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get(`api/julian/getExpiredLockers`)
    .then(response => {
      if (response.data.ResultCode===1) {
        //OFFICE
        let expiredOfficeLockersList = Object.keys(response.data.Data.office).map((key, i) => {
          return response.data.Data.office[key]
        })
        var expiredOfficeLockersListJoined = []
        expiredOfficeLockersList.forEach((item, i) => {
          expiredOfficeLockersListJoined = expiredOfficeLockersListJoined.concat(item)
        })

        //DELIVERY
        let expiredDeliveryLockersList = Object.keys(response.data.Data.delivery).map((key, i) => {
          return response.data.Data.delivery[key]
        })
        var expiredDeliveryLockersListJoined = []
        expiredDeliveryLockersList.forEach((item, i) => {
          expiredDeliveryLockersListJoined = expiredDeliveryLockersListJoined.concat(item)
        })

        dispatch({
          type: "GET_EXPIRED_LOCKERS_SUCCESS",
          payload: expiredLockers(expiredOfficeLockersListJoined, expiredDeliveryLockersListJoined)
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})
