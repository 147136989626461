import axios from "../../../utility/api"
import moment from 'moment'

const assetDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    name: item ? item.name : "",
    locker: item ? item.locker : [],
    category: item ? item.category : [],
    tag: item ? item.tag : "",
    status: item ? item.status : "",
    description: item ? item.description : "",
    createdAt: item ? item.createdAt : "",
    statusChangeAt: item ? item.statusChangeAt : "",
    tagStatus: item ? item.tagStatus : "",
    tagStatusChangeAt: item ? item.tagStatusChangeAt : "",
    enabled: item ? item.enabled : "",
    retrievedBy: item ? item.retrievedBy : [],
    schedules: item ? item.schedules : [],
    isRandomLocker: item ? item.isRandomLocker : true
  }
}

const logDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    assetId: item ? item.assetId : '',
    assetName: item ? item.assetName : '',
    actionId: item ? item.actionId : '',
    createdAt: item ? item.createdAt : '',
    description: item ? item.description : '',
    userId: item ? item.userId : '',
    username: item ? item.username : '',
    adminId: item ? item.adminId : '',
    adminName: item ? item.adminName : '',
    lockerId: item ? item.lockerId : '',
    doorNumber: item ? item.doorNumber : '',
    drawerMetraId: item ? item.drawerMetraId : '',
  }
}

const assetKpis = (item = false) => {
  return {
    totalUses: {
      last15Days: 30,
      last30Days: 47,
      last365Days: 292
    },
    averageDailyUses: {
      last15Days: 2,
      last30Days: 1.6,
      last365Days: 0.8
    },
    usesPerUser: [
            {
                "name": "Maria Antonieta",
                "userId": 824,
                "uses": "88"
            },
            {
                "name": "Afonso Henriques",
                "userId": 824,
                "uses": "79"
            },
            {
                "name": "Fernando Magalhães",
                "userId": 824,
                "uses": "78"
            },
            {
                "name": "Manuel Pires",
                "userId": 824,
                "uses": "57"
            },
            {
                "name": "Joana Rodrigues",
                "userId": 824,
                "uses": "30"
            },
            {
                "name": "Rute Marlene",
                "userId": 824,
                "uses": "27"
            },
            {
                "name": "António Costa",
                "userId": 824,
                "uses": "23"
            },
            {
                "name": "José Silva",
                "userId": 824,
                "uses": "14"
            },
            {
                "name": "Rita Fernandes",
                "userId": 824,
                "uses": "7"
            },
            {
                "name": "Ana Ribeiro",
                "userId": 824,
                "uses": "3"
            }
        ],
    usesLast60Days: {
            "last30Days": {
                "2021-06-15": 2,
                "2021-06-14": 3,
                "2021-06-13": 2,
                "2021-06-12": 2,
                "2021-06-11": 3,
                "2021-06-10": 1,
                "2021-06-09": 0,
                "2021-06-08": 3,
                "2021-06-07": 4,
                "2021-06-06": 4,
                "2021-06-05": 3,
                "2021-06-04": 4,
                "2021-06-03": 2,
                "2021-06-02": 1,
                "2021-06-01": 5,
                "2021-05-31": 4,
                "2021-05-30": 5,
                "2021-05-29": 3,
                "2021-05-28": 4,
                "2021-05-27": 1,
                "2021-05-26": 1,
                "2021-05-25": 3,
                "2021-05-24": 5,
                "2021-05-23": 2,
                "2021-05-22": 4,
                "2021-05-21": 3,
                "2021-05-20": 2,
                "2021-05-19": 2,
                "2021-05-18": 5,
                "2021-05-17": 4
            },
            "30DaysBefore": {
                "2021-05-16": 1,
                "2021-05-15": 2,
                "2021-05-14": 5,
                "2021-05-13": 4,
                "2021-05-12": 5,
                "2021-05-11": 6,
                "2021-05-10": 6,
                "2021-05-09": 3,
                "2021-05-08": 2,
                "2021-05-07": 4,
                "2021-05-06": 4,
                "2021-05-05": 5,
                "2021-05-04": 3,
                "2021-05-03": 6,
                "2021-05-02": 1,
                "2021-05-01": 1,
                "2021-04-30": 2,
                "2021-04-29": 5,
                "2021-04-28": 3,
                "2021-04-27": 6,
                "2021-04-26": 4,
                "2021-04-25": 2,
                "2021-04-24": 3,
                "2021-04-23": 4,
                "2021-04-22": 4,
                "2021-04-21": 6,
                "2021-04-20": 5,
                "2021-04-19": 4,
                "2021-04-18": 1,
                "2021-04-17": 3
            }
        }
  }
}
/*const assetKpis = (item = false) => {
  return {
    totalUses: item ? item.totalUses : [],
    averageDailyUses: item ? {
      last15Days: (item.totalUses['last15Days'] / 15).toFixed(2),
      last30Days: (item.totalUses['last30Days'] / 30).toFixed(2),
      last365Days: (item.totalUses['last365Days'] / 365).toFixed(2)
    } : [],
    usesPerUser: item ? item.usesPerUser : [],
    usesLast60Days: item ? item.usesLast60Days : []
  }
}*/

export const getAssets = (token, max_results, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset){
      dispatch({
        type: "GET_ASSETS_LIST_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/listAssets?max_results='+max_results)
    .then(response => {
      if (response.data.ResultCode===1) {
        let assetsList = response.data.Data.assets.map((item, i) => {
          return assetDetails(item)
        })
        dispatch({
          type: "GET_ASSETS_LIST_SUCCESS",
          payload: assetsList
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => reject())
})

export const getAssetDetails = (token,id,reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset === true){
      dispatch({
        type: "GET_ASSET_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_ASSET_DETAILS_SUCCESS",
        payload: assetDetails()
      });
      resolve()
      return
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/asset?assetId='+id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ASSET_DETAILS_SUCCESS",
          payload: assetDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    }).catch(err => reject())
})

export const setAssetDetails = (token, asset) => (dispatch) =>
new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  if (!asset.id || asset.id === 0) {
    axios.post('api/asset/asset', {
      name: asset.name,
      category: asset.category,
      tag: asset.tag,
      description: asset.description,
      isRandomLocker: asset.isRandomLocker
    })
    .then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ASSET_DETAILS_SUCCESS",
          payload: assetDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject(err)
    })
  } else {
    axios.put('api/asset/asset', {
      id: asset.id,
      name: asset.name,
      category: asset.category,
      tag: asset.tag,
      description: asset.description,
      isRandomLocker: asset.isRandomLocker,
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ASSET_DETAILS_SUCCESS",
          payload: assetDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
  }
})

export const deleteAsset = (token, asset) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.delete('api/asset/asset', {data: { assetId: asset.id }}).then(response => {
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const addUsersToAsset = (token, assetId, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.post('api/asset/addAssetUser', {
      AssetId: assetId,
      UserId: userId
    }).then(response => {
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const removeUsersFromAsset = (token, assetId, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.post('api/asset/removeAssetUser', {
      AssetId: assetId,
      UserId: userId
    }).then(response => {
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const getAssetsLogs = (token, page, rowsPerPage, filters,/*max_results,*/ reset = false) => (dispatch) =>
new Promise(function(resolve, reject){

  const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      //for date interval filter, parse date before submitting to filters string
      if(k === 'createdAtInterval'){
        var newDate = ''
        if(v[1]){
          Object.entries(v).forEach(([k2, v2]) => {
            if(k2 > 0) newDate = newDate + '|'
            newDate = newDate + moment(v2).format('YYYY-MM-DD')
          })
        }
        filterString = filterString + `&${k}=${newDate}`
      }else{
        filterString = filterString + `&${k}=${v}`
      }
    }
  })

  if(reset){
    dispatch({
      type: "GET_ASSETS_LOGS_SUCCESS",
      payload: false
    })
  }


    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/listAssetsLogs?max_results='/*+max_results*/+rowsPerPage+'&offset='+offset+filterString)
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let assetsLogsList = response.data.Data.logs.map((item, i) => {
          return logDetails(item)
        })
        dispatch({
          type: "GET_ASSETS_LOGS_SUCCESS",
          payload: {assetsLogs: assetsLogsList, maxRows: response.data.Data.totalLogs}
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getUserAssets = (token, userId, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset){
      dispatch({
        type: "GET_ASSETS_LIST_SUCCESS",
        payload: false
      })
    }

    if(userId === 'new'){
      dispatch({
        type: "GET_ASSETS_LIST_SUCCESS",
        payload: false
      })
      resolve()
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/listUserAssets?userId='+userId)
    .then(response => {
      if (response.data.ResultCode===1) {
        let assetsList = response.data.Data.assets.map((item, i) => {
          return assetDetails(item)
        })
        dispatch({
          type: "GET_ASSETS_LIST_SUCCESS",
          payload: assetsList
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => reject())
})

export const getAssetLogs = (token, max_results, assetId, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset){
      dispatch({
        type: "GET_SINGLE_ASSET_LOGS_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/listAssetLogs?max_results='+max_results+'&assetId='+assetId)
    .then(response => {
      if (response.data.ResultCode===1) {
        let assetLogsList = response.data.Data.assets.map((item, i) => {
          return logDetails(item)
        })
        dispatch({
          type: "GET_SINGLE_ASSET_LOGS_SUCCESS",
          payload: assetLogsList
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => reject())
})

export const getAssetKpis = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset){
      dispatch({
        type: "GET_ASSET_KPIS_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/assetKpis/'+id)
    .then(response => {
      if (response.data.ResultCode===1) {
        let assetKpi = assetKpis(response.data.Data)
        dispatch({
          type: "GET_ASSET_KPIS_SUCCESS",
          payload: assetKpi
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getAssetWarnings = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if(reset){
      dispatch({
        type: "GET_ASSETS_WARNINGS_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/assetWarnings')
    .then(response => {
      if (response.data.ResultCode===1) {
        let assetsWarnings = response.data.Data.map((item, i) => {
          return assetDetails(item)
        })
        dispatch({
          type: "GET_ASSETS_WARNINGS_SUCCESS",
          payload: assetsWarnings
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => reject())
})

export const enableAsset = (token,id) => (dispatch) =>
new Promise(function(resolve, reject){
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/enableAsset?assetId='+id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ASSET_DETAILS_SUCCESS",
          payload: assetDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    }).catch(err => reject())
})

export const disableAsset = (token,id) => (dispatch) =>
new Promise(function(resolve, reject){
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/asset/disableAsset?assetId='+id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ASSET_DETAILS_SUCCESS",
          payload: assetDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    }).catch(err => reject())
})

export const addSchedulesToAsset = (token, schedules, assetId) => (dispatch) =>
new Promise(function(resolve, reject) {
  var ids = ''
  for (var i = 0; i < schedules.length; i++) {
    if(ids === '') ids += schedules[i].id
    else ids += ',' + schedules[i].id
  }

  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/asset/addSchedules?assetId=' + assetId + '&scheduleIds=' + ids).then(response => {
    if (response.data.ResultCode===1) {
      dispatch({
        type: "GET_ASSET_DETAILS_SUCCESS",
        payload: assetDetails(response.data.Data)
      })
      resolve()
    } else {
      reject()
    }
  }).catch(err => {
    reject()
  })
})

export const removeSchedulesFromAsset = (token, schedules, assetId) => (dispatch) =>
new Promise(function(resolve, reject) {

  var ids = ''
  for (var i = 0; i < schedules.length; i++) {
    if(ids === '') ids += schedules[i].id
    else ids += ',' + schedules[i].id
  }

  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/asset/removeSchedules?assetId=' + assetId + '&scheduleIds=' + ids).then(response => {
    console.log(response)
    if (response.data.ResultCode===1) {
      dispatch({
        type: "GET_ASSET_DETAILS_SUCCESS",
        payload: assetDetails(response.data.Data)
      })
      resolve()
    } else {
      reject()
    }
  }).catch(err => {
    reject()
  })
})

export const addSchedulesToUserOwnsAsset = (token, schedules, assetId, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
  var ids = ''
  for (var i = 0; i < schedules.length; i++) {
    if(ids === '') ids += schedules[i].id
    else ids += ',' + schedules[i].id
  }

  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/userOwnsAsset/addSchedules?assetId=' + assetId + '&userId=' + userId + '&scheduleIds=' + ids).then(response => {
    if (response.data.ResultCode===1) {
      resolve()
    } else {
      reject()
    }
  }).catch(err => {
    reject()
  })
})

export const removeSchedulesFromUserOwnsAsset = (token, schedules, assetId, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
  var ids = ''
  for (var i = 0; i < schedules.length; i++) {
    if(ids === '') ids += schedules[i].id
    else ids += ',' + schedules[i].id
  }

  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get('api/userOwnsAsset/removeSchedules?assetId=' + assetId + '&userId=' + userId + '&scheduleIds=' + ids).then(response => {
    if (response.data.ResultCode===1) {
      resolve()
    } else {
      reject()
    }
  }).catch(err => {
    reject()
  })
})
