const faqsReducer = (state = { list: [], selected: null }, action) => {
  switch (action.type) {
    case "GET_FAQS_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_FAQS_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default faqsReducer