const voucherReducer = (state = { list : [], selected: null }, action) => {
  switch (action.type) {
    case "GET_VOUCHER_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_VOUCHER_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default voucherReducer
