const activityReducer = (state = { activityList : [], activityListOpenLocker : [] }, action) => {
  switch (action.type) {
    case "GET_ACTIVITY_SUCCESS":
      return { ...state, activityList: action.payload }
    case "GET_MARKETPLACE_ACTIVITY_SUCCESS":
      return { ...state, marketplaceActivityList: action.payload }
    case "GET_ACTIVITY_OPEN_LOCKER_SUCCESS":
      return { ...state, activityListOpenLocker: action.payload }
    default:
      return state
  }
}

export default activityReducer
