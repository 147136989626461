const marketplaceSettingsReducer = (state = { list : false, selected: null }, action) => {
  switch (action.type) {
    case "GET_MARKETPLACE_SETTINGS_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_IVA_TAX_SUCCESS":
      return { ...state, ivaTax: action.payload }
    case "GET_FREE_MODE_SUCCESS":
      return { ...state, freeMode: action.payload }
    default:
      return state
  }
}

export default marketplaceSettingsReducer
