const transactionsReducer = (state = { list : [], selected: false }, action) => {
  switch (action.type) {
    case "GET_TRANSACTIONS_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_TRANSACTION_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default transactionsReducer