import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_pt from "./translations/pt/common.json";
import common_en from "./translations/en/common.json";

i18n
  .use(initReactI18next)
  .init({
    interpolation: { 
        escapeValue: false 
    },
        lng: 'en',
        resources: {
            en: {
                common: common_en
            },
            pt: {
                common: common_pt
            },
        },
  });

export default i18n;
